import axios from "axios";
import { getToken, refreshToken } from "./AuthService";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });
    failedQueue = [];
};

const setupAxiosInterceptors = () => {
    axios.interceptors.request.use(
        (config) => {
            const token = getToken();
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error),
    );

    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            // Проверяем, если ошибка 401 и токен уже не пытались обновить
            if (error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    // Добавляем запрос в очередь
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((token) => {
                            originalRequest.headers["Authorization"] = `Bearer ${token}`;
                            return axios(originalRequest);
                        })
                        .catch((err) => Promise.reject(err));
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(async (resolve, reject) => {
                    try {
                        const newAccessToken = await refreshToken(); // Обновляем токен
                        if (newAccessToken) {
                            axios.defaults.headers.common["Authorization"] =
                                `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);
                            originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                            resolve(axios(originalRequest)); // Повторяем запрос с новым токеном
                        }
                    } catch (err) {
                        processQueue(err, null);
                        reject(err);
                    } finally {
                        isRefreshing = false;
                    }
                });
            }
            return Promise.reject(error);
        },
    );
};

export default setupAxiosInterceptors;
