interface CounterProps {
    fieldValue: string;
    maxLength: number;
}

const Counter: React.FC<CounterProps> = ({ fieldValue, maxLength }) => {
    const length = fieldValue.length;

    const getCounterColor = () => {
        if (length < maxLength * 0.5) return "red";
        if (length < maxLength) return "orange";
        return "green";
    };

    const getFeedbackMessage = () => {
        if (length < maxLength * 0.5) return "Слишком короткий текст. Добавьте больше информации.";
        if (length < maxLength) return "Хорошо, но можно ещё немного улучшить.";
        return "Отлично! Так держать.";
    };

    return (
        <div>
            <small style={{ color: getCounterColor() }}>{`${length}/${maxLength}`}</small>
            <span style={{ color: getCounterColor() }}>{getFeedbackMessage()}</span>
        </div>
    );
};

export default Counter;
