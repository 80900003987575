import React, { useState, useRef, useEffect } from "react";
import { addComment } from "../../services/CommentService";
import "./CommentForm.css";

interface CommentFormProps {
    postSlug: string;
    parentId?: number | null;
    replyTo?: { name: string; parentId: number } | null;
    onCommentAdded: (comment: any) => void;
    onCancelReply: () => void; // Функция отмены ответа
}

const CommentForm: React.FC<CommentFormProps> = ({
    postSlug,
    parentId = null,
    replyTo = null,
    onCommentAdded,
    onCancelReply,
}) => {
    const [body, setBody] = useState("");
    const [loading, setLoading] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    // Обновление высоты `textarea` при изменении текста
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "42px"; // Сброс высоты
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Установка высоты по содержимому
        }
    }, [body]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!body.trim()) return;

        setLoading(true);
        try {
            const newComment = await addComment(postSlug, body, replyTo?.parentId);
            onCommentAdded(newComment.comment);
            setBody("");
        } catch (error) {
            console.error("Ошибка при добавлении комментария:", error);
        } finally {
            setLoading(false);
            if (replyTo) onCancelReply(); // Сбрасываем состояние ответа
        }
    };

    return (
        <form onSubmit={handleSubmit} className="comment-form">
            <div className="comment-input-container">
                {replyTo && (
                    <div className="reply-info">
                        <span>Ответ {replyTo.name}</span>
                        <button type="button" className="reply-cancel" onClick={onCancelReply}>
                            X
                        </button>
                    </div>
                )}
                <textarea
                    ref={textareaRef}
                    className="comment-input"
                    value={body}
                    style={{ height: "40px" }}
                    onChange={(e) => setBody(e.target.value)}
                    placeholder={replyTo ? `Ответ ${replyTo.name}...` : "Напишите комментарий..."}
                    required
                />
            </div>
            
            <button type="submit" className="submit-comment" disabled={loading}>
                {loading ? (
                    <span className="spinner">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 50 50"
                        >
                            <circle
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="4"
                                stroke-dasharray="90,150"
                                stroke-dashoffset="0"
                            ></circle>
                        </svg>
                    </span>
                ) : (
                    <>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M12 19V5"></path>
                            <polyline points="5 12 12 5 19 12"></polyline>
                        </svg>
                    </>
                )}
            </button>

        </form>
    );
};

export default CommentForm;
