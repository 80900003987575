import React, { useState } from "react";
import { Comment } from "../../types/Comment";
import { updateComment, deleteComment } from "../../services/CommentService";
import "./CommentActions.css";

interface CommentActionsProps {
    comment: Comment;
    currentUserId: number;
    onCommentUpdated: (updatedComment: Comment) => void;
    onCommentDeleted: (deletedCommentId: number) => void;
}

const CommentActions: React.FC<CommentActionsProps> = ({
    comment,
    currentUserId,
    onCommentUpdated,
    onCommentDeleted,
}) => {
    const [isEditing, setIsEditing] = useState(false); // Режим редактирования
    const [editedBody, setEditedBody] = useState(comment.body); // Новый текст комментария

    if (comment.user?.id !== currentUserId) {
        return null;
    }

    const handleEdit = async () => {
        if (isEditing) {
            console.log("Updating comment:", comment.id, "New body:", editedBody);
            try {
                const updatedComment = await updateComment(comment.id, editedBody);
                console.log("Server response:", updatedComment);
                onCommentUpdated(updatedComment.comment);
            } catch (error) {
                console.error("Error updating comment:", error);
            }
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    };
    

    const handleDelete = async () => {
        const confirmDelete = window.confirm("Вы уверены, что хотите удалить этот комментарий?");
        if (!confirmDelete) return;
    
        try {
            // Вызов API для удаления
            await deleteComment(comment.id);
            console.log("Комментарий удален с ID:", comment.id);
            onCommentDeleted(comment.id); // Удаляем комментарий из списка
        } catch (error) {
            console.error("Ошибка при удалении комментария:", error);
        }
    };
    

    return (
        <div className="comment-actions">
            {isEditing ? (
                <div className="edit-comment">
                    <textarea
                        value={editedBody}
                        onChange={(e) => setEditedBody(e.target.value)}
                        rows={3}
                        className="edit-comment-textarea"
                    />
                    <button onClick={handleEdit}>Сохранить</button>
                    <button onClick={() => setIsEditing(false)}>Отмена</button>
                </div>
            ) : (
                <>
                    {/*<button onClick={handleEdit}>Редактировать</button>*/}
                    <button onClick={handleDelete}>Удалить</button>
                </>
            )}
        </div>
    );
};

export default CommentActions;
