import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Импорты изображений
import homeActive from "../../../assets/images/application-images/homeactive.webp";
import home from "../../../assets/images/application-images/home.webp";
import communityActive from "../../../assets/images/application-images/communityactive.webp";
import community from "../../../assets/images/application-images/community.webp";
import createActive from "../../../assets/images/application-images/createactive.webp";
import create from "../../../assets/images/application-images/create.webp";
import menuActive from "../../../assets/images/application-images/menuactive.webp";
import menu from "../../../assets/images/application-images/menu.webp";

import "./Footer.css"; // Подключение стилей

const Footer: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Определяем активные ссылки по текущему пути
    const isActive = (path: string) => location.pathname === path;

    // Функция для навигации по кнопке
    const handleNavigate = (url: string) => {
        navigate(url);
    };

    return (
        <footer className="footer">
            <nav className="footer-nav">
                {/* Главная */}
                <button
                    onClick={() => handleNavigate("/")}
                    className={`nav-button ${isActive("/") ? "active" : ""}`}
                >
                    <img src={isActive("/") ? homeActive : home} alt="Main" />
                    <div className="footer-name">Main</div>
                </button>

                {/* Сообщества */}
                <button
                    onClick={() => handleNavigate("/communities")}
                    className={`nav-button ${isActive("/communities") ? "active" : ""}`}
                >
                    <img
                        src={
                            isActive("/communities")
                                ? communityActive
                                : community
                        }
                        alt="Community"
                    />
                    <div className="footer-name">Community</div>
                </button>

                {/* Создать пост */}
                <button
                    onClick={() => handleNavigate("/posts/create")}
                    className={`nav-button ${isActive("/posts/create") ? "active" : ""}`}
                >
                    <img
                        src={isActive("/posts/create") ? createActive : create}
                        alt="Create"
                        className="create-img"
                    />
                    <div className="footer-name">Create</div>
                </button>

                {/* Меню */}
                <button
                    onClick={() => handleNavigate("/menu")}
                    className={`nav-button ${isActive("/menu") ? "active" : ""}`}
                >
                    <img
                        src={isActive("/menu") ? menuActive : menu}
                        alt="Menu"
                    />
                    <div className="footer-name">Menu</div>
                </button>
            </nav>
        </footer>
    );
};

export default Footer;
