import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PostDetail from "./pages/PostDetail";
import About from "./pages/About";
import Communities from "./pages/Communities";
import CreatePost from "./pages/CreatePost";
import Menu from "./pages/Menu";
import Header from "./components/layouts/Header/Header";
import Footer from "./components/layouts/Footer/Footer";
import LoginForm from "./components/Auth/LoginForm";
import { AuthProvider } from "./auth/AuthContext";
import setupAxiosInterceptors from "./auth/AxiosInterceptors"; // Подключаем interceptors
import "./styles/globals.css";

const App: React.FC = () => {
    useEffect(() => {
        setupAxiosInterceptors(); // Устанавливаем interceptors
    }, []);

    return (
        <AuthProvider>
            <Router>
                <Header />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/post/:slug" element={<PostDetail />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/communities" element={<Communities />} />
                        <Route path="/posts/create" element={<CreatePost />} />
                        <Route path="/menu" element={<Menu />} />
                        <Route path="/login" element={<LoginForm />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </AuthProvider>
    );
};

export default App;
