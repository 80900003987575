import React, { useState } from "react";
import axios from "axios";

import "./PostHeader.css";

import defaultAvatar from "../../assets/images/application-images/user-photo.webp";

interface PostHeaderProps {
    user?: {
        id: number;
        name: string;
        avatar?: string;
    };
    created_at: string;
    currentUserId: number;
    post: {
        id: number;
        user?: {
            id: number;
        };
    };
}

const PostHeader: React.FC<PostHeaderProps> = ({
    user,
    created_at,
    currentUserId,
    post,
}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleDeletePost = async (postId: number) => {
        try {
            await axios.delete(`https://syrqyn.kz/api/v1/post/${postId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            console.log("Пост успешно удалён.");
            // Здесь необходимо обновить состояние родительского компонента,
            // например, вызвать функцию `onDeletePost` из родителя.
        } catch (error: any) {
            console.error("Ошибка при удалении поста:", error.response?.data || error.message);
        }
    };

    const toggleMenu = (e: React.MouseEvent) => {
        e.preventDefault(); // Предотвращаем переход по ссылке
        e.stopPropagation(); // Останавливаем всплытие события
        setMenuOpen((prev) => !prev);
    };

    const handleMenuClick = (e: React.MouseEvent) => {
        e.preventDefault(); // Предотвращаем переход
        e.stopPropagation(); // Останавливаем всплытие события
    };

    return (
        <div className="post-header">
            <div className="post-header-left">
                <img
                    src={user?.avatar || defaultAvatar}
                    alt="User Avatar"
                    className="post-avatar"
                />
                <div>
                    <a
                        className="profile-link"
                        href={`/profile/${user?.id}`}
                        onClick={(e) => e.stopPropagation()} // Останавливаем всплытие при клике на имя
                    >
                        {user?.name || "Анонимный"}
                    </a>
                    <p className="post-date">{created_at}</p>
                </div>
            </div>
            
            {/* Три точки для меню */}
            {currentUserId === post.user?.id && (
                <div className="post-options">
                    <button
                        className="post-options-button"
                        onClick={toggleMenu}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            width="24"
                            height="24"
                        >
                            <circle cx="5" cy="12" r="1.5"></circle>
                            <circle cx="12" cy="12" r="1.5"></circle>
                            <circle cx="19" cy="12" r="1.5"></circle>
                        </svg>
                    </button>
                    {menuOpen && (
                        <div
                            className="options-menu"
                            onClick={handleMenuClick}
                        >
                            <button
                                className="delete-button"
                                onClick={(e) => {
                                    e.stopPropagation(); // Останавливаем всплытие
                                    handleDeletePost(post.id);
                                }}
                            >
                                Удалить пост
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PostHeader;
