import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { ru } from "date-fns/locale";
import "./PostList.css";
import PostHeader from "./PostHeader";
import PostStat from "./PostStat";
import PostSkeleton from "../Skeleton/PostSkeleton/PostSkeleton";

// Описываем интерфейс для постов
interface Post {
    id: number;
    title: string;
    content: string;
    slug: string;
    created_at: string;
    isLikedByUser: boolean;
    likers_count: number;
    comments_count: number;
    user?: {
        id: number;
        name: string;
        avatar?: string;
    };
}

const PostList: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currentUserId, setCurrentUserId] = useState<number | null>(null); // ID текущего пользователя
    const [locale] = useState(ru);

    // Получение ID текущего пользователя
    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await axios.get("https://syrqyn.kz/api/v1/me", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                setCurrentUserId(response.data.id);
            } catch (error) {
                console.error("Ошибка при получении пользователя:", error);
            }
        };

        fetchCurrentUser();
    }, []);

    // Запрос к API для получения постов
    const fetchPosts = (pageNumber: number) => {
        setLoading(true);
        axios
            .get(`https://syrqyn.kz/api/v1/posts?page=${pageNumber}`)
            .then((response) => {
                if (response.data.posts.length > 0) {
                    setPosts((prevPosts) => [...prevPosts, ...response.data.posts]);
                } else {
                    setHasMore(false);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Ошибка при загрузке постов:", error);
                setLoading(false);
            });
    };

    // Эффект для загрузки первой страницы
    useEffect(() => {
        fetchPosts(page);
    }, [page]);

    // Обработчик прокрутки
    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >=
                    document.body.offsetHeight - 500 &&
                hasMore &&
                !loading
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasMore, loading]);   

    useEffect(() => {
        const noHoverElements = document.querySelectorAll(".no-hover");

        noHoverElements.forEach((element) => {
            element.addEventListener("click", (event) => {
                event.stopPropagation();
            });
        });

        return () => {
            noHoverElements.forEach((element) => {
                element.removeEventListener("click", (event) => {
                    event.stopPropagation();
                });
            });
        };
    }, [posts]); // Обновляется при изменении списка постов


    return (
        <div className="posts-container">
            {loading && posts.length === 0 ? (
                <>
                    <PostSkeleton />
                    <PostSkeleton />
                    <PostSkeleton />
                </>
            ) : (
                posts.map((post) => (
                    <div key={post.id} className="post">
                        <a href={`/post/${post.slug}`} className="post-link">
                            <PostHeader
                                user={post.user}
                                created_at={post.created_at}
                                currentUserId={currentUserId}
                                post={post}
                            />
                            <h2 className="post-title">{post.title}</h2>
                            <div
                                className="formatted-text"
                                dangerouslySetInnerHTML={{ __html: post.content }}
                            />
                            <PostStat post={post} />
                        </a>
                    </div>
                ))
            )}
            {loading && <p>Загрузка...</p>}
            {!hasMore && <p>Больше постов нет</p>}
        </div>
    );
};

export default PostList;
