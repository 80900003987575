import React from "react";

const Communities: React.FC = () => {
    return (
        <div>
            <h1>Страница сообществ</h1>
            <h1>Страница сообществ</h1>
            <h1>Страница сообществ</h1>
        </div>
    );
};

export default Communities;
