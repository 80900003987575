// src/auth/AuthService.ts

const API_URL = "https://syrqyn.kz/api"; // Укажи свой URL API

// Сохранение access и refresh токенов
export const saveTokens = (
    accessToken: string,
    refreshToken: string | null = null,
) => {
    localStorage.setItem("access_token", accessToken);
    if (refreshToken) {
        localStorage.setItem("refresh_token", refreshToken);
    }
    console.log("Токены сохранены:", { accessToken, refreshToken });
};

// Получение access токена
export const getToken = (): string | null => {
    return localStorage.getItem("access_token");
};

// Получение refresh токена
export const getRefreshToken = (): string | null => {
    return localStorage.getItem("refresh_token");
};

// Удаление токенов
export const removeTokens = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
};

// Логин пользователя
export const login = async (email: string, password: string): Promise<any> => {
    const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    console.log("Ответ от API на логин:", data);
    if (response.ok && data.access_token && data.refresh_token) {
        saveTokens(data.access_token, data.refresh_token);
    }
    return data;
};

// Получение данных пользователя
export const fetchUserData = async (): Promise<any> => {
    const token = getToken();
    if (!token) throw new Error("Токен не найден");

    const response = await fetch(`${API_URL}/me`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok)
        throw new Error("Ошибка при получении данных пользователя");
    return response.json();
};

// Обновление access токена
export const refreshToken = async (): Promise<string | null> => {
    const refresh_token = getRefreshToken();
    if (!refresh_token) throw new Error("Refresh-токен не найден");

    const response = await fetch(`${API_URL}/refresh`, {
        method: "POST",
        headers: { Authorization: `Bearer ${refresh_token}` },
    });

    const data = await response.json();
    if (response.ok && data.access_token) {
        localStorage.setItem("access_token", data.access_token);
        return data.access_token;
    } else {
        removeTokens();
        return null;
    }
};

// Логаут пользователя
export const logout = async (): Promise<void> => {
    removeTokens();
};
