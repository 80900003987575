import React, { useState, useRef, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import Counter from "../components/Posts/Counter";
import { getToken } from "../auth/AuthService";
import "../styles/createPost.css";

interface CreatePostProps {
    isAdmin: boolean; // Проверка на администратора
}

const CreatePost: React.FC<CreatePostProps> = ({ isAdmin }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [content, setContent] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [filePreview, setFilePreview] = useState<string | null>(null); // Для предварительного просмотра
    const [loading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState("");
    const quillRef = useRef<HTMLDivElement>(null);
    const quillInstance = useRef<Quill | null>(null);

    const token = getToken();
    const isAuthenticated = !!token;

    useEffect(() => {
        if (quillRef.current && !quillInstance.current) {
            // Инициализация Quill только один раз
            quillInstance.current = new Quill(quillRef.current, {
                theme: "snow",
                placeholder: "Основной текст...",
                modules: {
                    toolbar: [
                        [{ header: [2, false] }],
                        ["bold", "italic", "underline"],
                        ["link"],
                        ["blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }],
                    ],
                },
            });

            quillInstance.current.on("text-change", () => {
                setContent(quillInstance.current!.root.innerHTML);
            });
        }
    }, []);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0] || null;
        setFile(selectedFile);

        // Генерация превью
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setFilePreview(event.target?.result as string);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setFilePreview(null); // Если файл сброшен
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append("title", title);
            formData.append("content", content);
            formData.append("is_anonymous", isAnonymous ? "1" : "0");
            if (file) formData.append("image", file);

            if (isAdmin) {
                formData.append("description", description);
                formData.append("keywords", keywords);
            }

            const response = await fetch("https://syrqyn.kz/api/v1/post", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: "POST",
                body: formData,
            });

            const result = await response.json();
            console.log("Post created:", result);
        } catch (error) {
            console.error("Error creating post:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="create-post-container">
            <h1>Создать пост</h1>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                {/* Заголовок */}
                <div>
                    <input
                        type="text"
                        className="input-title"
                        placeholder="Заголовок"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    {isAdmin && <Counter fieldValue={title} maxLength={60} />}
                </div>

                {/* Загрузка файла */}
                <div className="file-upload">
                    <label htmlFor="file-input" className="file-upload-label">
                        Выберите фотографию
                    </label>
                    <input
                        id="file-input"
                        type="file"
                        className="form-control-file"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                    <span>{file?.name || "Файл не выбран"}</span>

                    {/* Превью изображения */}
                    {filePreview && (
                        <div className="image-preview">
                            <img src={filePreview} alt="Превью изображения" />
                        </div>
                    )}
                </div>

                {/* Краткое описание и ключевые слова (только для администраторов) */}
                {isAdmin && (
                    <>
                        <div>
                            <textarea
                                className="input-text"
                                placeholder="Краткое описание"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <Counter fieldValue={description} maxLength={160} />
                        </div>

                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Ключевые слова (через запятую)"
                                value={keywords}
                                onChange={(e) => setKeywords(e.target.value)}
                            />
                        </div>
                    </>
                )}

                {/* Контент */}
                <div id="editor-container" ref={quillRef} className="quill-container" />

                {/* Анонимный чекбокс */}
                <div className="is-anonymous-input">
                    <label>
                        <input
                            type="checkbox"
                            checked={isAnonymous}
                            onChange={() => setIsAnonymous(!isAnonymous)}
                        />
                        <span>Анонимно</span>
                    </label>
                </div>

                {/* Кнопка отправки */}
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? (
                        <div className="spinner">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24" height="24">
                                <circle
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeDasharray="90,150"
                                    strokeDashoffset="0"
                                ></circle>
                            </svg>
                        </div>
                    ) : (
                        "Опубликовать"
                    )}
                </button>
            </form>
        </section>
    );
};

export default CreatePost;
