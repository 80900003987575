import React, { useState } from "react";
import CommentActions from "./CommentActions";
import { Comment } from "../../types/Comment";
import CommentForm from "./CommentForm";
import defaultAvatar from "../../assets/images/application-images/user-photo.webp";

interface CommentItemProps {
    comment: Comment;
    currentUserId: number;
    postSlug: string;
    onCommentUpdated: (updatedComment: Comment) => void;
    onCommentDeleted: (deletedCommentId: number) => void;
    onCommentAdded: (newComment: Comment) => void;
    handleReply: (name: string, parentId: number) => void; // Добавлен пропс для обработки ответа
}

const CommentItem: React.FC<CommentItemProps> = ({
    comment,
    currentUserId,
    postSlug,
    onCommentUpdated,
    onCommentDeleted,
    onCommentAdded,
    handleReply, // Используем handleReply
}) => {
    const [isReplying, setIsReplying] = useState(false);

    const toggleReply = () => {
        setIsReplying(!isReplying);
    };

    return (
        <div className="comment-container">
            <div className="comment-header">
                <img
                    src={comment.user?.avatar || defaultAvatar}
                    alt={comment.user?.name || "Anonymous"}
                />
                <div>
                    <h5>{comment.user?.name || "Анонимный"}</h5>
                    <div className="comment-date">{comment.created_at}</div>
                </div>
            </div>

            <p className="comment-body">{comment.body}</p>

            <div className="action-button">
                {/* Кнопка "Ответить" */}
                <button
                    onClick={() => {
                        handleReply(comment.user?.name || "Анонимный", comment.id);
                    }}
                >
                    Ответить
                </button>

                {/* Действия для комментариев (только для текущего пользователя) */}
                {currentUserId === comment.user?.id && (
                    <CommentActions
                        comment={comment}
                        currentUserId={currentUserId}
                        onCommentUpdated={onCommentUpdated}
                        onCommentDeleted={onCommentDeleted}
                    />
                )}
            </div>

            {/* Вложенные комментарии */}
            {comment.replies && comment.replies.length > 0 && (
                <div className="replies">
                    {comment.replies.map((reply) => (
                        <CommentItem
                            key={reply.id}
                            comment={reply}
                            currentUserId={currentUserId}
                            postSlug={postSlug}
                            onCommentUpdated={(updatedReply) => {
                                const updatedReplies = comment.replies.map((r) =>
                                    r.id === updatedReply.id ? updatedReply : r
                                );
                                onCommentUpdated({
                                    ...comment,
                                    replies: updatedReplies,
                                });
                            }}
                            onCommentDeleted={(deletedReplyId) => {
                                const updatedReplies = comment.replies.filter(
                                    (r) => r.id !== deletedReplyId
                                );
                                onCommentUpdated({
                                    ...comment,
                                    replies: updatedReplies,
                                });
                            }}
                            onCommentAdded={(newReply) => {
                                const updatedReplies = [...(comment.replies || []), newReply];
                                onCommentUpdated({
                                    ...comment,
                                    replies: updatedReplies,
                                });
                            }}
                            handleReply={handleReply} // Пробрасываем handleReply
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CommentItem;
