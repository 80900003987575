import React from "react";

import ruIcon from "../../../assets/images/application-images/ru.webp";
import kzIcon from "../../../assets/images/application-images/kz.webp";
import enIcon from "../../../assets/images/application-images/en.webp";

const LanguageSwitcher: React.FC = () => {
    return (
        <div className="header-lang dropdown">
            <div className="dropdown-toggle">
                {/* Отображаем активный язык (например, флаг) */}
                <img
                    src={ruIcon}
                    alt="Change Language"
                    className="menu-icons"
                />
            </div>
            <div className="dropdown-menu">
                <a href="/switchLang/kk">
                    <img
                        src={kzIcon}
                        alt="Change Language to Kazakh"
                        className="menu-icons"
                    />
                    Қазақ тілі
                </a>
                <a href="/switchLang/ru">
                    <img
                        src={ruIcon}
                        alt="Change Language to Russian"
                        className="menu-icons"
                    />
                    Русский
                </a>
                <a href="/switchLang/en">
                    <img
                        src={enIcon}
                        alt="Change Language to English"
                        className="menu-icons"
                    />
                    English
                </a>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
