import React from "react";
import { Link } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import ProfileMenu from "./ProfileMenu";
import "./Header.css"; // Подключаем стили для шапки

import headerLogo from "../../../assets/images/application-images/header_logo.webp";

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="search-bar">
                <div className="title">
                    {/* Логотип */}
                    <Link to="/">
                        <img
                            src={headerLogo}
                            alt="Syrqyn Logo"
                            className="header_logo"
                        />
                    </Link>
                    {/* Правый блок: Язык и профиль */}
                    <div className="header-right">
                        <LanguageSwitcher />
                        <ProfileMenu />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
