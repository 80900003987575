import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../auth/AuthService"; // Предполагается, что getToken возвращает токен
import { Comment } from "../types/Comment";

// Components
import PostHeader from "../components/Posts/PostHeader";
import PostStat from "../components/Posts/PostStat";
import CommentList from "../components/Comments/CommentList";
import CommentForm from "../components/Comments/CommentForm";

const PostDetail: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [post, setPost] = useState<any>(null);
    const [comments, setComments] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        next_page_url: null,
    });
    const [loadingPost, setLoadingPost] = useState(true);
    const [loadingComments, setLoadingComments] = useState(false);
    const [currentUserId, setCurrentUserId] = useState<number | null>(null); // ID текущего пользователя
    const [error, setError] = useState<string | null>(null);

    const [replyTo, setReplyTo] = useState<{ name: string; parentId: number } | null>(null);

    const token = getToken();
    const isAuthenticated = !!token;

    // Получение ID текущего пользователя
    useEffect(() => {
        const fetchCurrentUser = async () => {
            if (!isAuthenticated) return;

            try {
                const response = await axios.get("https://syrqyn.kz/api/v1/me", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCurrentUserId(response.data.id); // Убедитесь, что API возвращает ID пользователя
            } catch (err) {
                console.error("Ошибка при получении текущего пользователя:", err);
            }
        };

        fetchCurrentUser();
    }, [isAuthenticated, token]);

    // Загрузка поста и первой страницы комментариев
    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const response = await axios.get(
                    `https://syrqyn.kz/api/v1/post/${slug}`
                );
                setPost(response.data.post);
                setComments(response.data.comments.data);
                setPagination(response.data.comments.pagination);
            } catch (err) {
                console.error("Ошибка при загрузке поста:", err);
                setError("Ошибка при загрузке данных поста");
            } finally {
                setLoadingPost(false);
            }
        };

        fetchPostData();
    }, [slug]);

    const loadMoreComments = async () => {
        if (!pagination.next_page_url || loadingComments) return;

        setLoadingComments(true);
        try {
            const response = await axios.get(pagination.next_page_url);
            setComments((prev) => [...prev, ...response.data.data]);
            setPagination({
                current_page: response.data.current_page,
                last_page: response.data.last_page,
                next_page_url: response.data.next_page_url,
            });
        } catch (err) {
            console.error("Ошибка при загрузке комментариев:", err);
        } finally {
            setLoadingComments(false);
        }
    };

    if (loadingPost) {
        return (
            <div className="loading-indicator">
                <div className="spinner"></div>
            </div>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!post) {
        return <p>Пост не найден</p>;
    }

    const handleCommentAdded = (newComment: Comment) => {
        const parentExists = newComment.parent_id
            ? comments.some((comment) => comment.id === newComment.parent_id)
            : true;
    
        if (!parentExists) {
            console.error("Родительский комментарий не найден!");
            return;
        }
    
        setComments((prevComments) => {
            if (newComment.parent_id) {
                return prevComments.map((comment) =>
                    comment.id === newComment.parent_id
                        ? {
                              ...comment,
                              replies: [...(comment.replies || []), newComment],
                          }
                        : comment
                );
            }
            return [newComment, ...prevComments];
        });
    };
    

    const handleCommentUpdated = (updatedComment: any) => {
        setComments((prev) =>
            prev.map((comment) =>
                comment.id === updatedComment.id ? updatedComment : comment
            )
        );
    };

    const handleCommentDeleted = (deletedCommentId: number) => {
        setComments((prev) =>
            prev.filter((comment) => comment.id !== deletedCommentId)
        );
    };

    const handleReply = (name: string, parentId: number) => {
        setReplyTo({ name, parentId });
    };

    const cancelReply = () => {
        setReplyTo(null);
    };

    return (
        <div className="post-container post-detail">
            {/* SEO Schema */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "NewsArticle",
                    mainEntityOfPage: {
                        "@type": "WebPage",
                        "@id": window.location.href,
                    },
                    author: {
                        "@type": "Person",
                        name: post.is_anonymous ? "Анонимный" : post.user?.name,
                        ...(post.user && { url: `/profile/${post.user?.id}` }),
                    },
                    publisher: {
                        "@type": "Organization",
                        name: "Syrqyn",
                        url: "/",
                        image: {
                            "@type": "ImageObject",
                            url: "/images/application-images/favicon.ico",
                            width: 1300,
                            height: 300,
                        },
                    },
                    url: window.location.href,
                    headline: post.title,
                    description: post.description,
                    articleBody: post.content,
                    datePublished: post.created_at,
                    dateModified: post.updated_at,
                    ...(post.image && {
                        image: {
                            "@type": "ImageObject",
                            url: post.image,
                            width: 1200,
                            height: 800,
                        },
                    }),
                })}
            </script>
            <div className="post">
                {/* Заголовок */}
                <PostHeader 
                    user={post.user} 
                    created_at={post.created_at}
                    currentUserId={currentUserId}
                    post={post}
                />

                {/* Контент */}
                <div className="post-content">
                    <h1 className="post-title">{post.title}</h1>
                    {post.image && (
                        <img
                            src={post.image}
                            alt={post.title}
                            className="img-fluid"
                        />
                    )}
                    <div
                        className="formatted-text"
                        dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                </div>

                {/* Статистика */}
                <PostStat post={post} />
            </div>

            {/* Комментарии */}
            <section className="comments-section">
                <h2>Комментарии</h2>
                {isAuthenticated && (
                    <CommentForm
                        postSlug={post.slug}
                        replyTo={replyTo}
                        onCommentAdded={handleCommentAdded}
                        onCancelReply={cancelReply}
                    />
                )}
                <CommentList
                    comments={comments}
                    postSlug={post.slug}
                    currentUserId={currentUserId} // Передаём ID текущего пользователя
                    onCommentUpdated={handleCommentUpdated}
                    onCommentDeleted={handleCommentDeleted}
                    onCommentAdded={(newComment) => {
                        setComments((prevComments) => {
                            // Если это ответ, найдите родительский комментарий
                            if (newComment.parent_id) {
                                return prevComments.map((comment) =>
                                    comment.id === newComment.parent_id
                                        ? {
                                            ...comment,
                                            replies: [...(comment.replies || []), newComment],
                                        }
                                        : comment
                                );
                            }
                            // Если это верхний комментарий, просто добавьте его
                            return [newComment, ...prevComments];
                        });
                    }}
                    handleReply={handleReply}
                />

                {pagination.next_page_url && (
                    <button
                        onClick={loadMoreComments}
                        disabled={loadingComments}
                        className="load-more-button"
                    >
                        {loadingComments ? "Загрузка..." : "Загрузить еще"}
                    </button>
                )}
            </section>
        </div>
    );
};

export default PostDetail;
