import React from "react";
import "./PostSkeleton.css"; // Подключаем стили для скелетона

// Компонент для отображения скелетона загрузки
const SkeletonLoader: React.FC = () => {
    return (
        <>
            <div className="post-skeleton">
                <div className="skeleton-header">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-content">
                        <div className="skeleton-line long"></div>
                        <div className="skeleton-line short"></div>
                    </div>
                </div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
            </div>
        </>
    );
};

export default SkeletonLoader;
