import React from "react";
import "./HeaderSkeleton.css"; // Подключаем стили для скелетона
import headerLogo from "../../../assets/images/application-images/header_logo.webp";

const HeaderSkeleton: React.FC = () => {
    return (
        <div className="header-skeleton">
            <div>
                <img
                    src={headerLogo}
                    alt="Syrqyn Logo"
                    className="header_logo"
                />
            </div>{" "}
            {/* Скелетон для логотипа */}
            <div className="skeleton-nav">
                <div className="skeleton-profile"></div>{" "}
                {/* Скелетон для профиля */}
                <div className="skeleton-profile"></div>{" "}
                {/* Скелетон для профиля */}
            </div>
        </div>
    );
};

export default HeaderSkeleton;
