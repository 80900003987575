import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactNode,
} from "react";
import {
    fetchUserData,
    getToken,
    removeTokens,
    login as authLogin,
    saveTokens,
    logout as authLogout,
    refreshToken,
} from "./AuthService";
import SkeletonLoader from "../components/Skeleton/SkeletonLoader"; // Импортируем компонент скелетона

// Интерфейс для данных пользователя
interface User {
    id: number;
    name: string;
    email: string;
    avatar?: string;
}

// Интерфейс для данных контекста
interface AuthContextType {
    isAuthenticated: boolean;
    user: User | null;
    login: (email: string, password: string) => Promise<any>;
    logout: () => void;
}

// Интерфейс для пропсов AuthProvider
interface AuthProviderProps {
    children: ReactNode;
}

// Создаем контекст
export const AuthContext = createContext<AuthContextType | undefined>(
    undefined,
);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Добавляем состояние загрузки

    // Проверка токена при первой загрузке
    useEffect(() => {
        const token = getToken();
        if (token) {
            fetchUserData()
                .then((userData: User) => {
                    setIsAuthenticated(true);
                    setUser(userData);
                })
                .catch(() => setIsAuthenticated(false))
                .finally(() => setLoading(false)); // Завершаем загрузку
        } else {
            setLoading(false); // Если нет токена, тоже завершаем загрузку
        }
    }, []);

    const login = async (email: string, password: string) => {
        const data = await authLogin(email, password);
        if (data.access_token) {
            saveTokens(data.access_token);
            setIsAuthenticated(true);
            fetchUserData().then((userData: User) => setUser(userData));
        }
        return data;
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUser(null);
        authLogout();
    };

    // Показываем скелетон во время загрузки
    if (loading) {
        return <SkeletonLoader />; // Показываем скелетон во время загрузки
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Хук для доступа к контексту
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
