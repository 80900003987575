import React from "react";

import "./PostStat.css"

import heart from "../../assets/images/application-images/heart.webp";
import heartActive from "../../assets/images/application-images/heartactive.webp";
import commentIcon from "../../assets/images/application-images/comment.webp";

interface Post {
    isLikedByUser: boolean;
    likers_count: number;
    comments_count: number;
}

interface PostStatProps {
    post: Post; // объект post
}

const PostStat: React.FC<PostStatProps> = ({ post }) => {
    return (
        <div className="no-hover post-stats">
            <div className="like-icon">
                <img
                    src={
                        post.isLikedByUser
                            ? heartActive
                            : heart
                    }
                    alt="Like"
                />
                <span>{post.likers_count}</span>
            </div>
            <div className="comment-icon">
                <img src={commentIcon} alt="Comments" />
                <span>{post.comments_count}</span>
            </div>
        </div>
    );
};


export default PostStat;
