import React, { useState } from "react";
import { useAuth } from "../../auth/AuthContext"; // Импортируем контекст авторизации
import "./LoginForm.css";
import googleIcon from "../../assets/images/application-images/icons8-google-240.webp";
import yandexIcon from "../../assets/images/application-images/yandex.webp";
import eyeIcon from "../../assets/images/application-images/eye-icon.webp";
import eyeSlashIcon from "../../assets/images/application-images/eye-slash-icon.webp";

const LoginForm: React.FC = () => {
    const { login } = useAuth(); // Берем функцию login из контекста
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await login(email, password);
            window.location.href = "/";
        } catch (error) {
            console.error("Ошибка авторизации:", error);
            alert("Ошибка авторизации");
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = "https://syrqyn.kz/auth/google";
    };

    const handleYandexLogin = () => {
        window.location.href = "https://syrqyn.kz/auth/yandex";
    };

    return (
        <div className="login-form-container">
            <h2>Войти</h2>
            <form onSubmit={handleSubmit} className="login-form">
                <div className="input-group">
                    <label htmlFor="email">E-mail</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div className="input-group password-input-group">
                    <label htmlFor="password">Пароль</label>
                    <div className="password-wrapper">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <img
                            src={showPassword ? eyeSlashIcon : eyeIcon}
                            alt="Toggle visibility"
                            className="toggle-password-icon"
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                </div>

                <div className="remember-me">
                    <label>
                        <input
                            type="checkbox"
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                        />
                        Запомнить меня
                    </label>
                </div>

                <button type="submit" className="login-button">
                    Войти
                </button>
            </form>

            <div className="divider">или</div>

            <div className="social-buttons">
                <button onClick={handleGoogleLogin} className="google-button">
                    <img src={googleIcon} alt="Google" />
                    Войти через Google
                </button>
                <button onClick={handleYandexLogin} className="yandex-button">
                    <img src={yandexIcon} alt="Yandex" />
                    Войти через Яндекс
                </button>
            </div>
        </div>
    );
};

export default LoginForm;
