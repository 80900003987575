import axios from "axios";
import { getToken } from "../auth/AuthService";

const API_URL = "https://syrqyn.kz/api/v1";

export const addComment = async (postSlug: string, body: string, parentId?: number) => {
    const token = getToken();
    
    const response = await axios.post(
        `${API_URL}/comment/${postSlug}`,
        { body, parent_id: parentId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};

export const updateComment = async (commentId: number, body: string) => {
    const token = getToken(); // Добавить токен
    console.log('update')
    const response = await axios.patch(
        `${API_URL}/comment/${commentId}/update`,
        { body },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};


export const deleteComment = async (commentId: number) => {
    const token = getToken(); // Добавить токен
    const response = await axios.delete(`${API_URL}/comment/${commentId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};
