import React from "react";
import CommentItem from "./CommentItem";
import { Comment } from "../../types/Comment";

import "./CommentItem.css";

interface CommentListProps {
    comments: Comment[]; // Список комментариев
    postSlug: string;
    currentUserId: number; // ID текущего пользователя
    onCommentUpdated: (updatedComment: Comment) => void;
    onCommentDeleted: (deletedCommentId: number) => void;
    onCommentAdded: (newComment: Comment) => void; // Добавлен пропс
    handleReply: (name: string, parentId: number) => void;
}

const CommentList: React.FC<CommentListProps> = ({
    comments,
    postSlug,
    currentUserId,
    onCommentUpdated,
    onCommentDeleted,
    onCommentAdded,
    handleReply,
    
}) => {
    return (
        <div className="comments-list">
            {comments.map((comment) => (
                <CommentItem
                    key={comment.id}
                    comment={comment}
                    currentUserId={currentUserId}
                    postSlug={postSlug}
                    onCommentUpdated={onCommentUpdated}
                    onCommentDeleted={onCommentDeleted}
                    onCommentAdded={onCommentAdded} // Передаем обработчик добавления комментария
                    handleReply={handleReply}
                />
            ))}
        </div>
    );
};

export default CommentList;
