import React from "react";
import HeaderSkeleton from "./Layouts/HeaderSkeleton";
import PostSceleton from "./PostSkeleton/PostSkeleton";

// Компонент для отображения скелетона загрузки
const SkeletonLoader: React.FC = () => {
    return (
        <>
            <HeaderSkeleton />
            <PostSceleton />
            <PostSceleton />
            <PostSceleton />
        </>
    );
};

export default SkeletonLoader;
