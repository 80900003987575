import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../auth/AuthContext";
import userPhoto from "../../../assets/images/application-images/user-photo.webp";

const ProfileMenu: React.FC = () => {
    const { isAuthenticated, user } = useAuth();

    return (
        <>
            {isAuthenticated ? (
                <Link to="/profile">
                    <img
                        src={user?.avatar || userPhoto}
                        alt="User profile"
                        className="menu-icons"
                    />
                </Link>
            ) : (
                <Link to="/login">
                    <img src={userPhoto} alt="Профиль" className="menu-icons" />
                </Link>
            )}
        </>
    );
};

export default ProfileMenu;
