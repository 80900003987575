import React from "react";
import PostList from "../components/Posts/PostList"; // Подключаем компонент PostList
import Header from "../components/layouts/Header/Header";
import Footer from "../components/layouts/Footer/Footer";

const Home: React.FC = () => {
    return (
        <div>
            <Header />
            <main className="main-content-home">
                <PostList /> {/* Отображение списка постов */}
            </main>
            <Footer />
        </div>
    );
};

export default Home;
