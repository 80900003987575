import React from "react";

const Menu: React.FC = () => {
    return (
        <div>
            <h1>Меню</h1>
            <h1>Меню</h1>
            <h1>Меню</h1>
            <a href="/logout">jhjhjh</a>
        </div>
    );
};

export default Menu;
